"use strict";
jQuery(document).ready(function ($) {
  $(".home.section-one #scroll-down").click(function () {
    $("html, body").animate(
      {
        scrollTop: $(".home.section-two").offset().top - 50,
      },
      1000
    );
  });

  $(".home.section-six.stop").click(function () {
    var video = $(".home.section-six video").get(0);
    $(".home.section-six").removeClass("stop");
    video.play();
    $(".home.section-six .play").addClass("hide");
    $(".home.section-six .mask").addClass("hide");
    $(".home.section-six").addClass("start");
    stop();
  });
  function start() {
    var video = $(".home.section-six video").get(0);
    $(".home.section-six.stop").click(function () {
      $(".home.section-six").removeClass("stop");
      video.play();
      $(".home.section-six .play").addClass("hide");
      $(".home.section-six .mask").addClass("hide");
      $(".home.section-six").addClass("start");
      stop();
    });
  }

  function stop() {
    var video = $(".home.section-six video").get(0);
    $(".home.section-six.start").click(function () {
      $(".home.section-six").removeClass("start");
      video.pause();
      $(".home.section-six .play").removeClass("hide");
      $(".home.section-six .mask").removeClass("hide");
      $(".home.section-six").addClass("stop");
      start();
    });
  }
});
